import React, { Fragment } from 'react';
import Store from 'eas-react-store';
import Dropdown from 'react-dropdown';
import moment from 'moment-timezone';
import DatePicker, { registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { Tools } from '../../../tools/tools';
import svSE from 'date-fns/locale/sv';
registerLocale('sv-SE', svSE);


class CreateNewTask extends React.Component {

	constructor(props) {
		super(props);

		this.optionGroups = [];

		if (this.props.store.Group != null && this.props.store.Group.data != null) {
			this.props.store.Group.data.forEach((group, index) => {
				this.optionGroups.push({ value: index, label: Tools.makeBeautifulLabel(group.name), id: group.id });
			});
		}

		this.optionCategories = [];

		if (this.props.store.Category != null) {
			this.props.store.Category.data.forEach((category, index) => {
				this.optionCategories.push({ value: index, label: category.title, id: category.id });
			});
		}

		this.optionLocations = [];

		if (this.props.store.Task.data.locations != null) {
			this.props.store.Task.data.locations.forEach((location, index) => {
				this.optionLocations.push({ value: index, label: `${location.title}, ${location.address}`, id: location.id });
			});
		}

		this.state = {
			description: "",
			end_date_time: "",
			spots: 0,
			start_date: new Date(this.props.store.Schedule.selectedDateOnNewTaskClick), // auto fill the start date
			end_time: "",
			end_date: new Date(this.props.store.Schedule.selectedDateOnNewTaskClick),
			category: this.optionCategories[0], // Dropdown
			location: this.optionLocations[0], // Dropdown
			owner_group: this.optionGroups[0], // Dropdown
			title: "",

			/* Location selector and creation on the go */
			isShowingNewLocationField: false,
			newLocationName: "",
			newLocationAddress: "",
			locationsList: this.props.store.Task.data.locations,

			/* Repeating event feature */
			endRepeatingDate: "",
			isRepeatingEvent: false,
			useGroupsWeeks: 1
		}

		// Tasks will be created by default for group 1
		// This case should never happend though.
		this.connectedUserGroup = 1;
		if (this.props.store.User.data.connectedUser != null) {
			this.connectedUserGroup = this.props.store.User.data.connectedUser.group_ids[0]
		}

		this.onDropdownChange = this.onDropdownChange.bind(this);
	}

	showWarningMessage(message) {
		this.setState({
			isShowingWarning: true,
			warningMessage: message
		})
	}

	saveCurrentEdits() {
		this.props.store.Schedule.savedEdits = {
			"total_spots": this.state.total_spots,
			"description": this.state.description,
			"category": this.state.category,
			"title": this.state.title,
			"start_date_day": this.state.start_date_day,
			"start_date_time": this.state.start_date_time,
			"end_date_time": this.state.end_date_time
		}
	}

	/**
	 * This function is called when the save button is clicked
	 * It handles all repeating event related actions.
	 */
	async handleRepeatingTask(taskToSend, useGroupsWeeks) {
		let task = taskToSend;
		const endRepeating = moment(this.state.endRepeatingDate);
		let startDate = moment.tz(this.state.start_date, 'Europe/Paris');
		let endDate = moment.tz(this.state.end_date, 'Europe/Paris');

		/* Ignores the first date that will be handled by the main onSubmit function.
		 * Adds one day to the loop so that the last selected day is included */
		startDate.add(7, 'day');
		endDate.add(7, 'day');

		while (startDate <= endRepeating.add(1, 'day')) {
			let newStartDate = moment(startDate);
			let newEndDate = moment(endDate);

			task.start_date = newStartDate.utc().format();
			task.end_date = newEndDate.utc().format();

			if (useGroupsWeeks) {
				let weekNumber = moment.utc(startDate).isoWeek();
				let year = moment.utc(startDate).year();

				task.owner_group = this.findGroupOfWeek(weekNumber, year);
			}

			if (!task.owner_group) {
				return;
			}

			await this.props.store.Task.createTask(task, true);

			startDate.add(7, 'day');
			endDate.add(7, 'day');
		}
		this.props.store.Task.fetchTasksByWeek(this.props.store.Schedule.currentDay);
	}

	// MARK: Handling input change event

	/**
	 * Handles the change of value of all the input fields
	 * @param {Event} evt The JS event
	 */
	onInputChange(evt) {
		this.setState({
			[evt.target.name]: evt.target.value
		})
	}

	/**
	 * Handles the change of value of the location dropdown
	 * @param {Event} event JS Event
	 */
	onLocationChange(event) {
		this.setState((state) => ({
			...state,
			location: this.optionLocations[event.value]
		}));
	}

	/**
	 * Handles the change of value of the category dropdown
	 * @param {Event} event JS Event
	 */
	onCategoryChange(event) {
		this.setState((state) => ({
			...state,
			category: this.optionCategories[event.value]
		}));
	}

	/**
	 * Returns the class string for a given checkbox.
	 * It checks if the box is checked or not and dynamically add/removes
	 * the check symbol from the box.
	 */
	getCheckboxClassName() {
		let className = "custom-check-wrapper";
		className += this.state.isRepeatingEvent ? " checked" : "";
		return className
	}

	handleCheckbox() {
		this.setState({
			isRepeatingEvent: !this.state.isRepeatingEvent
		})
	}

	/**
	 * Handles the click on the add button
	 * We use the local state to avoid re-rendering the entire schedule
	 * when that value is changed.
	 */
	handleClickOnAddSpot() {
		const newNb = this.state.spots + 1;
		this.setState(() => ({
			spots: newNb
		}))
	}

	/**
	 * Handles the click on the new location button
	 */
	handleClickOnAddLocation() {
		this.setState({
			isShowingNewLocationField: true
		})
	}

	/**
	 * Handles the click on confirm new location button
	 */
	handleClickOnConfirmNewLocation(evt) {
		const newLocation = {
			"title": this.state.newLocationName,
			"address": this.state.newLocationAddress
		}
		this.saveCurrentEdits();
		this.props.store.Task.createLocation(newLocation);
	}

	/**
	 * Handles the click on remove location
	 */
	handleClickOnRemoveLocation(evt) {
		this.saveCurrentEdits();

		if (this.state.location == null) {
			var conf = window.alert("Det finns ingen adress att ta bort!")
		} else {
			var conf = window.confirm("Är du säker på att du vill ta bort den här adressen?")
			if (conf === true) {
				this.props.store.Task.removeLocation(this.state.location.id);
			}
		}
	}


	/**
	 * Handles the click on the minus button
	 * We make sure that the user can not set the available spots number
	 * to a lower number than 0.
	 */
	handleClickOnRemoveSpot() {
		if ((this.state.spots - 1) >= 0) {
			const newNb = this.state.spots - 1;
			this.setState((state, props) => ({
				spots: newNb
			}))
		}
	}

	// MARK: - Handle DatePicker changes

	/**
	 * Handle change on start date picker
	 * TODO: - Find a workaround to avoid the reset of end_date, while still changing the day of the end_date
	 */
	handleStartDatePickerChange(e) {
		this.showWarningMessage("");
		const duration = Date.parse(this.state.end_date) - Date.parse(this.state.start_date)
		this.setState({
			start_date: e,
			end_date: moment(e).add(duration, 'milliseconds').toDate()
		})
	}

	/**
	 * Handle change on end date picker
	 */
	handleEndDatePickerChange(e) {
		this.setState({
			end_date: e
		})
	}

	/**
	 * Handle change on start date picker
	 */
	handleRepeatDatePickerChange(e) {
		this.setState({
			endRepeatingDate: e,
			isRepeatingEvent: true
		})
	}

	getRadioClassName(value) {
		let className = "custom-check-wrapper";
		className += this.state.useGroupsWeeks === value ? " checked" : "";
		return className
	}

	handleRadio(e) {
		this.setState({
			useGroupsWeeks: Number(e.target.value)
		});
	}

	findGroupOfWeek(weekNumber, year) {
		let groupToAssign = 0;

		this.props.store.Group.data.forEach((group, index) => {
			group.weeks.forEach((week) => {
				if (week.year === year && week.week === weekNumber) {
					groupToAssign = group.id
				}
			});
		});

		if (!groupToAssign) {
			this.showWarningMessage('Ingen grupp är schemalagd den här veckan')
		}

		return groupToAssign;
	}

	/**
	 * Handles the click on the save button
	 * We read all the data from the state and create a task object
	 * matching the API specification before sending it through a PUT request.
	 */
	handleClickOnCreateNewTask() {
		// Create the new task object
		let taskToSend = {};

		// Attributes that can be changed from the form
		taskToSend.title = this.state.title;
		taskToSend.description = this.state.description;
		taskToSend.spots = this.state.spots;
		taskToSend.location = this.state.location.id;
		taskToSend.category = this.state.category.id;
		taskToSend.start_date = moment.utc(this.state.start_date).format();
		taskToSend.end_date = moment.utc(this.state.end_date).format();

		if (this.props.store.User.data.connectedUser.userlevel === 'admin' || this.props.store.User.data.connectedUser.userlevel === 'super_admin') {
			if (this.state.useGroupsWeeks) {
				let weekNumber = moment.utc(this.state.start_date).isoWeek();
				let year = moment.utc(this.state.start_date).year();

				taskToSend.owner_group = this.findGroupOfWeek(weekNumber, year);
			} else {
				taskToSend.owner_group = this.state.owner_group.id;
			}
		} else {
			taskToSend.owner_group = this.props.store.User.data.connectedUser.group_ids[0];
		}

		if (taskToSend.spots > 500) {
			this.showWarningMessage("Du kan inte har mer än 500 platser")

			return;
		}

		if (taskToSend.start_date > taskToSend.end_date) {
			this.showWarningMessage("Tiderna är inte korrekta, välj startdatum igen.");

			return;
		} else if (!taskToSend.owner_group) {
			return;
		} else {
			this.props.store.Task.createTask(taskToSend);
			this.props.store.Schedule.cancelCreateNewTask();
			if (this.state.isRepeatingEvent) {
				this.handleRepeatingTask(taskToSend, this.state.useGroupsWeeks);
			}
		}
	}

	// MARK: Render methods

	/**
	 * Renders a dropdown menu containing the existing possible locations for the task.
	 * @param {Int} id The ID of the current location of the task, to have it automatically selected.
	 */
	renderLocationSelector(id) {
		return (
			<Fragment>
				<div className="d-flex justifiy-content-between">
					<Dropdown
						options={this.optionLocations}
						onChange={this.onLocationChange.bind(this)}
						value={this.state.location}
						placeholder="Välj en adress"
					/>
					<button className="btn btn-inline add" onClick={this.handleClickOnAddLocation.bind(this)}><span>+</span></button>
					<button className="btn btn-inline address-remove remove" onClick={this.handleClickOnRemoveLocation.bind(this)}> </button>

				</div>
				{this.renderCreateLocationField()}
			</Fragment>
		);
	}

	/**
	 * Renders the new location input field
	 */
	renderCreateLocationField() {
		let returnArr = [];
		if (this.state.isShowingNewLocationField) {
			returnArr.push(
				<Fragment key="locationFields">
					<input
						type="text"
						className="form-control edit-input mt-2"
						name="newLocationName"
						value={this.state.newLocationName}
						onChange={this.onInputChange.bind(this)}
						placeholder="Skriv namnet på platsen här ..."
						required />
					<div className="d-flex justifiy-content-between mt-2">
						<input
							type="text"
							className="form-control edit-input"
							name="newLocationAddress"
							value={this.state.newLocationAddress}
							onChange={this.onInputChange.bind(this)}
							placeholder="Skriv adressen på platsen här ..."
							required />
					</div>
					<div className="d-flex justify-content-end mt-2">
						<button
							className="btn btn-inline confirm"
							onClick={this.handleClickOnConfirmNewLocation.bind(this)}><span>✓</span>Spara ny adress</button>
					</div>
				</Fragment>
			)
		}
		return returnArr;
	}

	/**
	 * Renders a dropdown menu containing the existing possible categories for the task
	 * @param {Int} id The ID of the current category of the task, to have it automatically selected
	 */
	renderCategorySelector(id) {
		return (
			<Dropdown
				options={this.optionCategories}
				onChange={this.onCategoryChange.bind(this)}
				value={this.state.category}
				placeholder="Välj en kategori"
			/>
		);
	}

	/**
	 * Renders the warning if the input data is not valid
	 */
	renderWarning() {
		let returnArr = [];
		if (this.state.isShowingWarning) {
			if (this.state.warningMessage !== "") {
				returnArr.push(
					<p className="warning-message">{this.state.warningMessage}</p>
				)
			}
		}
		return returnArr;
	}

	onDropdownChange(event) {
		this.setState((state) => ({
			...state,
			owner_group: this.optionGroups[event.value]
		}));
	}

	render() {
		return (
			<Fragment>
				<div className={`slide-create-new-task-overlay ${this.props.isActive ? '' : "hidden"}`}>
					<div className={`slide-overview slide-create-new-task ${this.props.isActive ? 'active' : ""}`}>

						<div className="slide-overview-alert-container">
							<button className="slide-overview-close" aria-label="Stäng" onClick={() => this.props.store.Schedule.cancelCreateNewTask()}></button>
						</div>

						<div className="slide-overview-head">
							<label className="edit-label" htmlFor="title">Titel</label>
							<input
								type="text"
								name="title"
								className="form-control edit-input"
								onChange={this.onInputChange.bind(this)}
								value={this.state.title}
								required />

							<div className="d-flex flex-row my-2">
								<div className="flex-datepicker">
									<label className="edit-label" htmlFor="date">Startid</label>
									<DatePicker
										className="form-control edit-input"
										onKeyDown={(e) => {
											e.preventDefault();
										}}
										dateFormat="EEEE d MMMM, HH:mm"
										timeFormat="HH:mm"
										selected={this.state.start_date}
										onChange={(e) => this.handleStartDatePickerChange(e)}
										locale="sv-SE"
										minDate={new Date()}
										showTimeSelect
										showWeekNumbers
										timeIntervals={15} />
								</div>
								<div className="flex-timepicker">
									<label className="edit-label" htmlFor="date">Sluttid</label>
									<DatePicker
										className="form-control edit-input"
										onKeyDown={(e) => {
											e.preventDefault();
										}}
										dateFormat="HH:mm"
										timeFormat="HH:mm"
										selected={this.state.end_date}
										onChange={(e) => this.handleEndDatePickerChange(e)}
										locale="sv-SE"
										showTimeSelect
										showTimeSelectOnly
										timeIntervals={15} />
								</div>
							</div>


							<div className="form-check my-3">
								<label htmlFor="repeatCheckbox" className={this.getCheckboxClassName()}>
									<span className="custom-check"></span>
									<input type="checkbox" name="repeat" id="repeatCheckbox" onChange={(e) => this.handleCheckbox(e)} />
								</label>
								<div className="full-width-datepicker">
									<label className="form-check-label d-block">
										Skapa kopior varje vecka fram till
									</label>
									<DatePicker
										className="form-control edit-input"
										dateFormat="EEEE d MMMM"
										selected={this.state.endRepeatingDate}
										onChange={(e) => this.handleRepeatDatePickerChange(e)}
										locale="sv-SE"
										minDate={new Date()}
										showWeekNumbers />
								</div>
							</div>

							<label className="edit-label" htmlFor="place">Plats</label>
							{this.renderLocationSelector(this.state.location)}

							<label className="edit-label" htmlFor="category">Kategori</label>
							{this.renderCategorySelector(this.state.category)}
						</div>

						<div className="slide-overview-desc">
							<label className="edit-label" htmlFor="description">Beskrivning</label>
							<textarea
								name="description"
								className="form-control edit-input"
								value={this.state.description}
								onChange={this.onInputChange.bind(this)}
							></textarea>
						</div>

						<div className="slide-overview-spots">
							<div className="spots-top-bar d-flex align-items-center justify-content-start">
								<label htmlFor="avaliable-spots" className="counter-label">Tillgängliga platser</label>

								<div className="counter-container">
									<span className="counter-btn subtract" onClick={() => this.handleClickOnRemoveSpot()}></span>
									<input
										type="number"
										name="spots"
										placeholder="0"
										className="form-control edit-input"
										onChange={this.onInputChange.bind(this)}
										value={this.state.spots} />
									<span className="counter-btn add" onClick={() => this.handleClickOnAddSpot()}></span>
								</div>

								<span className="spots-available ml-auto">0/{this.state.spots}</span>
							</div>


							{(this.props.store.User.data.connectedUser.userlevel === "admin" ||
								this.props.store.User.data.connectedUser.userlevel === "super_admin") &&
								<Fragment>
									<div className="form-check my-3">
										<label htmlFor="useGroupsWeeks" className="custom-check-wrapper">
											<span className={`custom-check custom-radio ${this.state.useGroupsWeeks === 1 ? 'checked' : ''}`}>
												{this.state.useGroupsWeeks === 1 &&
													<span className="onSelect"></span>
												}
											</span>
											<input
												type="radio"
												name="groupsWeeks"
												value="1"
												id="useGroupsWeeks"
												onChange={(e) => this.handleRadio(e)}
											/>
										</label>
										<label className="form-check-label d-block">
											Använd grupperna från schemat
										</label>
									</div>

									<div className="form-check my-3">
										<label htmlFor="useCustomGroup" className="custom-check-wrapper">
											<span className={`custom-check custom-radio ${this.state.useGroupsWeeks === 0 ? 'checked' : ''}`}>
												{this.state.useGroupsWeeks === 0 &&
													<span className="onSelect"></span>
												}
											</span>
											<input
												type="radio"
												name="groupsWeeks"
												value="0"
												id="useCustomGroup"
												onChange={(e) => this.handleRadio(e)}
											/>
										</label>
										<label className="form-check-label d-block">
											Välj grupp
										</label>
									</div>

									{!this.state.useGroupsWeeks &&
										<div>
											<label>Grupp</label>
											<Dropdown
												options={this.optionGroups}
												value={this.state.owner_group}
												onChange={this.onDropdownChange}
											/>
										</div>
									}
								</Fragment>
							}

						</div>

						{this.renderWarning()}

						<div className="slide-overview-controls">
							<button className="btn btn-cancel" onClick={() => this.props.store.Schedule.cancelCreateNewTask()}>Avbryt</button>
							<button className="btn btn-save" onClick={() => this.handleClickOnCreateNewTask()}>Spara</button>
						</div>

					</div>
				</div>
			</Fragment>
		);
	}
}

export default Store.connectStore(CreateNewTask);
import React, { Fragment } from 'react';
import Store from 'eas-react-store';
import moment from 'moment';
import { Tools } from '../../tools/tools';
import PeopleIcon from '../svg/people';
import { SpotPill } from './SpotPill';

class TaskCard extends React.Component {

    getRandomInt(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    renderTime() {
        const startDate = moment(this.props.start_date).format('HH:mm');
        const endDate = moment(this.props.end_date).format('HH:mm');
        const returnTime = startDate + " - " + endDate;

        return returnTime;
    }

    renderCategory(waitingList) {
        let rgbColor = Tools.hexToRgbA(this.props.category.color);
        let divStyle = {
            color: rgbColor,
            backgroundColor: rgbColor.replace(/[^,]+(?=\))/, '0.2')
        }
        let className = "category"

        if (waitingList > 0) {
            className += " fix-margin"
        }
        if (this.props.isCardSelected) {
            divStyle = {}; // No style override if the card is selected
        }

        return (
            <div className={className} style={divStyle}>{this.props.category.title}</div>
        )
    }

    render() {
        let className = "task";
        className += this.props.isCardSelected ? " is-selected" : "";

        if (this.props.store.Task.isDueSoonAndIsNotFilled(this.props)) {
            className += " has-warning";
        }

        return (
            <div className={className} onClick={this.props.onClick}>
                <div className="date">{this.renderTime()}</div>
                <div className="title">{this.props.title}</div>
                <div className="task-details">
                    <SpotPill
                        available_spots={this.props.available_spots}
                        takenSpots={this.props.takenSpots}
                        waitingList={this.props.waitingList}
                        isCardSelected={this.props.isCardSelected}
                    />
                    {this.renderCategory(this.props.waitingList.length)}
                </div>
            </div>
        );
    }
}

export default Store.connectStore(TaskCard);
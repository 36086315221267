import React, { Fragment } from 'react';
import SelectUserModal from "../../../components/select-user-modal/SelectUserModal";
import { Tools } from '../../../tools/tools';
import Store from 'eas-react-store';
import moment from 'moment';
import localization from 'moment/locale/sv';
import IconAnonymous from './IconAnonymous';
import { SpotPill } from '../../../components/task-card/SpotPill';

class ScheduleSlideOverview extends React.Component {

	constructor(props) {
		super(props);
		this.task = this.props.store.Schedule.selectedTask;
		this.groupManager = this.props.store.Schedule.selectedTaskGroupManager;

		// Sets the first task of the schedule as default
		if (this.task === null && this.props.store.Task.data.tasks.length > 0) {
			this.task = this.props.store.Task.data.tasks[0];
		} else if (this.task === null && this.props.store.Task.data.tasks.length <= 0) {
			this.task = this.props.store.Task.getFakeTask();
		}


		let inputs = {};
		let searchs = {};

		for (let index = 1; index <= this.task.available_spots; index++) {
			inputs["item" + (index)] = "";
			searchs["item" + (index)] = "";
		}

		this.state = {
			available_spots: this.task.available_spots,
			taken_spots: this.task.taken_spots,
			isRecommendingTask: false,
			inputs,
			searchs
		}

		this.handleInput = this.handleInput.bind(this);
		this.onClickAddUser = this.onClickAddUser.bind(this);
	}

	// MARK: - Logic functions

	async removeOneSpot(e, user) {
		let response = await this.props.store.Task.deleteTaskFromUser(this.task.id, user.id);

		if (response) {
			let accepted_by = [];

			this.task.accepted_by.forEach((acceptedByUser) => {
				if (acceptedByUser.id !== user.id) {
					accepted_by.push(acceptedByUser);
				}
			});

			if (this.task.waiting_list.length > 0) {
				accepted_by.push(this.task.waiting_list[0]);
				this.task.waiting_list.shift();
			} else {
				this.task.available_spots += 1;
				this.task.taken_spots -= 1;
			}

			this.task.accepted_by = accepted_by;

			this.props.store.Schedule.selectedTask = this.task;
			this.props.store.Schedule.updateStore();
		}
	}

	// MARK: - Click handlers

	handleClickOnRemove() {
		var conf = window.confirm("Är du säker på att du vill ta bort den här uppgiften?")
		if (conf === true) {
			this.props.store.Task.deleteTask(this.task.id);
			this.props.store.Schedule.unSelectAll();
		}
	}

	toggleRecommendingTaskView() {
		this.setState({
			isRecommendingTask: !this.state.isRecommendingTask
		})
	}

	// MARK: - Rendering methods

	renderTime() {
		moment().locale('sv', localization);
		const startDate = moment(this.task.start_date);
		const endDate = moment(this.task.end_date);
		var returnTime = startDate.format('dddd D MMMM, HH:mm');
		returnTime += endDate.format(' - HH:mm');

		return returnTime
	}

	renderLocation() {
		const title = this.task.location.title;
		const address = this.task.location.address;

		if (title !== "") {
			return title + ", " + address;
		}

		return address;
	}

	renderCategory() {
		if (this.task.category.title != null) {
			let rgbColor = Tools.hexToRgbA(this.task.category.color);
			let divStyle = {
				color: rgbColor,
				backgroundColor: rgbColor.replace(/[^,]+(?=\))/, '0.2')
			}
			return (
				<div className="category" style={divStyle}>
					{this.task.category.title}
				</div>
			)
		}
	}

	renderManager() {
		let returnArray = [];
		if (this.groupManager != null) {
			let groupManagerName = this.groupManager.name !== "" ? this.groupManager.name : "Ingen manager"
			let initials = Tools.getInitials(groupManagerName);
			let currentGroup = this.props.store.Group.data.find((group) => group.id === this.task.owner_group);
			currentGroup = currentGroup.name.split(' ')[1];

			returnArray.push(
				<div className="manager" key={1}>
					<div className="manager-image">
						<span>{initials}</span>
					</div>
					<span className="manager-name">{groupManagerName}<span>Grupp {currentGroup}</span></span>
				</div>
			)
		}

		return returnArray;
	}

	handleInput(event) {
		event.persist();

		this.setState((state) => ({
			...state,
			inputs: {
				...state.inputs,
				[event.target.name]: event.target.value
			},
			searchs: {
				...state.searchs,
				[event.target.name]: this.searchUsers(event.target.value)
			}
		}));
	}

	searchUsers(query) {
		let result = [];

		if (query !== "") {
			this.props.store.User.data.users.forEach((user) => {
				if (user.name.toLowerCase().includes(query.toLowerCase())) {
					result.push(user);
				}
			});
		}

		return result;
	}

	async onClickAddUser(event, user) {
		let response = await this.props.store.Task.assignTaskToUser(this.task.id, user.id);

		if (response) {
			this.task.accepted_by.push(user);
			this.task.available_spots -= 1;
			this.task.taken_spots += 1;

			this.setState((state) => ({
				...state,
				available_spots: this.state.available_spots - 1,
				taken_spots: this.state.taken_spots + 1
			}));
			this.props.store.Schedule.selectedTask = this.task;
			this.props.store.Schedule.updateStore();
		}
	}

	renderAcceptedBy() {
		let returnArray = [];
		let remainingAvailableSpots = this.state.available_spots;

		this.task.accepted_by.forEach((user) => {
			var initials = Tools.getInitials(user.name);

			returnArray.push(
				<li className="spot user" key={user.id + 1000}>
					<div className="user-image">
						{user.user_privacy === "anonymous" ?
							<IconAnonymous />
							:
							<span>
								{initials}
							</span>
						}
					</div>
					<span className="user-name">
						{user.name}
					</span>
					<button className="remove-spot ml-auto" aria-label="Ta bort" onClick={(event) => this.removeOneSpot(event, user)}></button>
				</li>
			);
		});

		for (let i = 0; i < remainingAvailableSpots; i++) {
			let inputState = this.state.inputs["item" + (i + 1)];

			returnArray.push(
				<div key={i}>
					<li className="spot">
						<div className="spot-image"></div>
						<input type="text" className="spot-input" onChange={this.handleInput} name={"item" + (i + 1)} value={inputState} placeholder="Börja skriva för att tilldela platsen" />
					</li>
					{inputState.length > 0 &&
						<li>
							<ul className="spotSearch">
								{this.state.searchs["item" + (i + 1)].map((user) => (
									<li onClick={(event) => this.onClickAddUser(event, user)} key={user.id}>{user.name}</li>
								))}
							</ul>
						</li>
					}
				</div>
			)
		}

		return returnArray;
	}

	renderDueSoonAlert() {
		var returnArr = [];
		if (this.props.store.Task.isDueSoonAndIsNotFilled(this.task)) {
			returnArr = (
				<div className="slide-overview-alert">
					<span>Detta pass utförs snart och platserna är ännu inte fyllda!</span>
				</div>
			)
		}
		return returnArr;
	}

	renderRecommendingTaskView() {
		if (this.state.isRecommendingTask) {
			return <SelectUserModal
				store={this.props.store}
				mode="recommend-task"
				taskId={this.task.id}
				onClickOnAbort={this.toggleRecommendingTaskView.bind(this)} />;
		}
	}

	render() {
		return (
			<Fragment>
				<div className={`slide-overview ${this.props.isActive ? 'active' : ""}`}>

					<div className="slide-overview-head">
						<button className="slide-overview-close" aria-label="Stäng" onClick={() => this.props.store.Schedule.unSelectAll()}></button>
						<span className="date">{this.renderTime()}</span>
						<h2>{this.task.title}</h2>
						<span className="place">{this.renderLocation()}</span>

						{this.renderCategory()}
						{this.renderDueSoonAlert()}
					</div>

					<div className="slide-overview-desc">
						<h3>Beskrivning</h3>
						<p>{this.task.description}</p>
					</div>

					<div className="slide-overview-manager">
						{this.renderManager()}
					</div>

					<div className="slide-overview-spots">
						<div className="spots-top-bar">
							<h3>Tillgängliga platser</h3>
							<SpotPill
								available_spots={this.state.available_spots}
								takenSpots={this.state.taken_spots}
								waitingList={this.task.waiting_list}
								isCardSelected={false}
							/>
						</div>
						<ul className="spots user-list">
							{this.renderAcceptedBy()}
						</ul>
						<button className="btn btn-primary w-100" onClick={() => this.toggleRecommendingTaskView()}>Rekommendera</button>
					</div>

					{this.task.waiting_list.length > 0 &&
						<div className="slide-overview-spots">
							<div className="spots-top-bar">
								<h3>Väntelista</h3>
							</div>
							<ul className="spots user-list">
								{this.task.waiting_list.map((user, idx) => (
									<li className="waiting-list-user"><span className="waiting-list-position">{idx + 1}</span>{user.name}</li>
								))}
							</ul>
						</div>
					}

					{this.props.store.User.data.connectedUser.userlevel !== "team_leader" &&
						<div className="slide-overview-controls">
							<button className="btn btn-primary edit" onClick={() => this.props.store.Schedule.activateEditMode()}>Redigera</button>
							<button className="btn btn-primary copy" onClick={() => this.props.store.Task.duplicateTask(this.task)}>Kopiera</button>
							<button className="btn btn-primary remove" onClick={() => this.handleClickOnRemove()}>Ta bort</button>
						</div>
					}
				</div>
				{this.renderRecommendingTaskView()}
			</Fragment>
		);
	}
}

export default Store.connectStore(ScheduleSlideOverview);